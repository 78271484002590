<template>
  <div>
    <eden-page-header :title="'Vouchers'" :subtitle="name" />

    <template>
      <div>
        <VoucherSummary :voucher="voucher" />

        <section class="mt-5">
          <eden-table-actions
            :title="`${voucher?.usage_logs?.length} Customer(s)`"
            @search="search"
          >
          </eden-table-actions>
          <eden-loader v-if="loading" />
          <template v-else>
            <el-table
              v-if="voucher?.usage_logs?.length"
              :data="filteredUsageLogs"
            >
              <el-table-column>
                <template slot="header">
                  <span> Name </span>
                </template>
                <template slot-scope="scope">
                  <router-link
                    class="text-primary"
                    :to="{
                      name: 'customers.individual',
                      params: { id: scope.row.customer_id },
                    }"
                    >{{
                      scope.row.customer_name
                        ? formatName(scope.row.customer_name)
                        : "-"
                    }}</router-link
                  >
                </template>
              </el-table-column>
              <el-table-column>
                <template slot="header">
                  <span> Email </span>
                </template>
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.customer_email &&
                      scope.row.customer_email.toLowerCase()
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot="header">
                  <span> Number of Times Used </span>
                </template>
                <template slot-scope="scope">
                  <p>{{ scope.row.customer_usage_count || 0 }}</p>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot="header">
                  <span> Date first used </span>
                </template>
                <template slot-scope="scope">
                  <p>{{ formatDate(scope.row.earliest_usage_date) }}</p>
                </template>
              </el-table-column>
            </el-table>
            <eden-content-empty v-else :text="'No data'" />
          </template>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import VoucherSummary from "../../components/vouchersAndDiscounts/vouchers/VoucherSummary.vue";
import voucher from "@/requests/vouchers/voucher";

export default {
  name: "Vouchers",
  components: { VoucherSummary },
  data() {
    return {
      voucher: {},
      searchQuery: "",
      loading: false,
    };
  },
  computed: {
    name() {
      const voucherName = this.voucher?.code;
      return this.formatName(voucherName);
    },
    voucherId() {
      return this.$route.params.id;
    },
    filteredUsageLogs() {
      const query = this.searchQuery.toLowerCase();
      return this.voucher.usage_logs.filter(
        (log) =>
          log.customer_name.toLowerCase().includes(query) ||
          log.customer_email.toLowerCase().includes(query),
      );
    },
  },
  created() {
    this.getVoucher();
  },
  methods: {
    async getVoucher() {
      try {
        this.isLoading = true;
        const response = await voucher.voucher(this.voucherId);
        if (response.data.status) {
          this.voucher = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    search(query) {
      this.searchQuery = query;
    },
  },
};
</script>
